import React, { createContext, useState } from "react";

export const InvoiceContext = createContext();

export const InvoiceProvider = ({ children }) => {
  const [invoices, setInvoices] = useState([]);
  const [isPass, setIsPass] = useState(false);

  const setInvoicesFunc = (invoices) => {
    setIsPass(true);
    setInvoices(invoices);
  };

  return (
    <InvoiceContext.Provider value={{ setInvoicesFunc, invoices, isPass }}>
      {children}
    </InvoiceContext.Provider>
  );
};
